.mapWrapper {
  width: 96%;
  height: 96%;
  margin-left: auto;
  margin-right: auto;
}

.mapMarkerWrapper {
  position: relative;
}

.mapMarkerBallon {
  position: absolute;
  bottom: calc(100% + 28px);
  left: 3px;
  z-index: 9;
  display: block;
  width: 200px;
  padding: 9px 12px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 28%);
  word-break: break-word;
}

.mapMarkerBallonClose {
  position: absolute;
  top: 0;
  right: 2px;
  height: auto;
  width: auto;
  padding: 0;
  line-height: 1;
  border: none;
  background: transparent;
}

.mapMarkerBallonCloseIcon {
  font-size: 12px;
}

.mapPin {
  position: absolute;
  bottom: calc(100% - 2px);
  left: -3px;
  font-size: 28px;
}

.content {
  margin: 0 auto 10px;
  display: flex;
}

.map {
  margin-top: 60px;
  min-height: 10%;
  height: 80%;
  max-height: 90%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mapBox{
  height: 900px;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.printButton{
  margin-left: 10px;
}