.div {
  display: flex;
  width: 100%;
}

.nav {
  flex-grow: 1;
  min-width: 200px;
}

.navMenu {
  border-bottom: none;
}

.userNav {
  flex-shrink: 0;
  display: flex;
  margin-left: 40px;
}

.userEmail {
  display: none;
  cursor: pointer;

  @media screen and (min-width: 992px) {
    display: block;
  }
}

.userIcon {
  display: block;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.logotype {
  flex-shrink: 0;
  width: 115px;
  margin-top: 7px;
  margin-right: 20px;
  margin-bottom: -3px;
}

.logOut {
  margin-left: 40px;
  color: rgba(0, 0, 0, 0.85);
}
