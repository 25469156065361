.tableContainer {
  position: relative;
}

.table {
  width: 96%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.tableFullWidth {
  width: 100%;
}

.table tr:nth-child(2n) td {
  background-color: #d6d6d6;
}

.table tbody > tr:hover > td {
  background-color: #cde7ff !important;
}

.tableInfo {
  position: absolute;
  top: -27px;
  right: 2%;
}

.tableInfoIcon {
  font-size: 18px;
}

.tableInfoContainer {
  max-height: 350px;
  overflow-y: auto;
}
