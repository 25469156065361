.atn-card {
  flex-basis: 100%;
  height: 100%;
}

.title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.1;
}

.description {
  margin-bottom: 8px;
}

.button {
  margin-top: 15px;
}
