.table {
  width: 96%;
  margin: 10px auto;

  td:empty {
    padding: 0;
  }

  tr:nth-child(2n) td {
    background-color: #d6d6d6;
  }

  tbody > tr:hover > td {
    background-color: #cde7ff !important;
  }
}

.tableContainer {
  position: relative;
}

.footer {
  font-size: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blocks {
  display: flex;
}

.block {
  padding-right: 10px;
}

.tableInfo {
  position: absolute;
  top: -27px;
  right: 2%;
}

.tableInfoIcon {
  font-size: 18px;
}

.tableFullWidth {
  width: 100%;
}

.button {
  margin-left: 30px;
  margin-top: 30px;
}

.expandedTable {
  margin-top: 10px;
  margin-left: 115px;
  margin-bottom: 10px;

  tr:nth-child(2n) td {
    background-color: #e3e3e3;
  }

  tr:nth-child(2n + 1) td {
    background-color: #d6d6d6;
  }
}

.titlePadding {
  padding: 0 50px;
}

.title {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.subTitle {
  width: 96%;
  margin-left: 5%;
  margin-top: 20px;
}

.tabs {
  margin-left: 2.5%;
}

.searchForm {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  form {
    > div {
      margin-bottom: 10px !important;
    }
  }
}

.noData {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.noDataFullWidth {
  width: 100%;
}

.noDataNestedTable {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.formFooter {
  button {
    margin-right: 10px;
  }
}

.infoMessage {
  @media screen and (max-width: 1600px) {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 1601px) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
.fileContainer{
  display: flex
}

.icon{
margin: 5px
}

.tableTotal {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -45px;
  font-weight: bold;
}

.col {
  @media screen and (max-width: 1250px) {
    margin-left: -37%;
  }
}

.owner {
  margin-left: -23%;
  width: 150%;
}

.row {
  @media screen and (max-width: 1250px) {
    width: 180%;
  }
}

.simpleTableTotal {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  font-weight: bold;
}

.actionsRow {
  > a {
    margin-right: 10px;
  }
}

.content {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.contentWithTab {
  width: 96%;
}

.formItem {
  margin-bottom: 10px !important;
}

.formWrapper {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.colorsMap {
  width: 96%;
  margin-top: 20px !important;
}

.colorsMapItem {
  display: inline;
  margin-right: 10px;
  padding: 3px;

  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 2px;
  }
}
