body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editable-row.row-odd td {
  background-color: #bfbfbf;
}

.editable-row.row-even td {
  background-color: #fff !important;
}

table > tbody > tr.expanded-row:hover > td {
  background-color: inherit !important;
}

.ant-table-row-expand-icon-cell,
.ant-table-expand-icon-col {
  display: none;
}

.search-form .ant-form-item-explain {
  font-size: 0.9em;
  line-height: 1.2;
  max-width: 181px;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin-right: -5px !important;
}

.ant-layout-header
  > div
  > div:first-child
  .ant-menu-overflow
  > .ant-menu-item:first-child::after {
  border-bottom-color: transparent !important;
}

@media print {
  table {
    max-width: 100% !important;
    table-layout: fixed !important;
  }

  .ant-tabs-content {
    flex-direction: column;
  }

  .ant-tabs-content > div {
    display: block !important;
  }
}
