.title {
  text-align: center;
}

.logotype {
  margin: 24px auto 0;
  width: 200px;
}

.formWrapper {
  flex-grow: 1;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8%;
  bottom: 0;
  position: absolute;
}

@media (700px <= width <= 1280px) {
  .dataSecurity {
    width: 60%;
  }
}

@media (width >= 1280px) {
  .dataSecurity {
    width: 25%;
  }
}

.forgotPassword {
  margin-left: 10px;
}

.button {
  margin-top: 10px;
}
