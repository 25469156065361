@use './color';
@use './type';
@use "./components";

body {
  color: color.$color-base-gray;
  background-color: color.$color-base-gray;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-weight: type.$default-font-style;
}

b {
  font-weight: type.$medium-font-weight;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px;
  overflow-wrap: break-word;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
}

.ant-layout-header {
  height: auto;
  background-color: #fff;
}

.ant-table-thead th {
  font-weight: 600 !important;
}

.ant-table-cell-fix-left .ant-table-cell-fix-left-last {
  left: 0 !important;
}

.ant-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.463);
}