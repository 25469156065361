.root {
  margin-bottom: 16px;
}

.table {
  border: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
}

.cell {
  border: 1px solid #ddd;
  padding: 5px;
}

.tableHead {
  border: 1px solid #ddd;
  font-weight: 700;
  padding: 5px;
  text-align: start;
}
