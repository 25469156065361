.root {
  width: 100%;
  margin: 30px auto;
  padding: 0 50px;
}

.breadcrumb {
  width: 96%;
  margin: 0 auto 16px;
}

.title {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
